.mb-20 {
  margin-bottom: 20px;
}

.link {
  color: #232323;

  &:hover,
  &:active {
    color: #23232390;
  }
}
