@import "./assets/fonts/fonts.scss";


body {
  margin: 0;
  font-family: 'Druk Cyr', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
  outline: none;
}

*::before, *::after {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6, p, ul, figure {
  margin: 0;
}
